import { Fragment, useEffect, useState } from 'react';
import { InfoStrip, Section } from '../Layout';
import OrderBox, { OrderBoxSkeleton } from '../OrderBox';

import { GroupByTrip } from '../../dataLayer/classes/OrdersCollection';
import { Translate, useTranslate } from '../../system/i18n/useTranslate';
import { useAppState } from '../../dataLayer/AppState';
import {
    OPTION_TOGGLE,
    SINGLE_OPTION_TOGGLE,
} from '../../dataLayer/AppState/actions';
import Alternatives from '../Alternatives';
import useRideInfo from '../../dataLayer/useRideInfo';
import { Tag } from '@flixbus/honeycomb-react';
import useStatus from '../../dataLayer/useStatus';
import { OrderAlternatives } from '../OrderBox/OrderBox';
import Order from '../../dataLayer/classes/Order';
import { Pagination } from '../Pagination';

interface OrderList {
    rideId: string;
    isLoading: boolean;
    isFetching: boolean;
    groupByTrip?: GroupByTrip;
}

export default function OrderSingleList({
    rideId,
    isLoading,
    isFetching,
    groupByTrip,
}: OrderList) {
    const translate: Translate = useTranslate();
    const [appState, dispatch] = useAppState();
    const {
        options: selectedOptions = {},
        alternatives = {},
        customAlternatives = {},
        selectedStops,
        ghostMeta,
        isGhost,
    } = appState;
    const ride = useRideInfo(rideId, ghostMeta || true);
    const [alternativesDialog, showAlternatives] =
        useState<null | OrderAlternatives>(null);

    const [rebookingStatuses, setRebookingStatuses] = useState<any>({});

    const { data: statusData } = useStatus(rideId);
    useEffect(() => {
        if (statusData && Object.entries(statusData).length) {
            const statuses = statusData.body.reduce((acc, rebookStatus) => {
                return {
                    ...acc,
                    [String(rebookStatus.orderId)]: rebookStatus.status,
                };
            }, {});
            setRebookingStatuses(statuses);
        }
    }, [statusData]);

    const selectOption = (order: Order) => (payload: any) => {
        const { orderId, option, suggestion } = payload;
        const { originTripId, replaceTripId, originTripUuid, replaceTripUuid } =
            option;
        const isCustom = suggestion.isCustom || false;

        dispatch(OPTION_TOGGLE, {
            orderId,
            option: {
                originTripId,
                replaceTripId,
                originTripUuid,
                replaceTripUuid,
                isItPartialRebooking: isCustom,
            },
        });
        dispatch(SINGLE_OPTION_TOGGLE, {
            orderId,
            option: {
                originTripId,
                replaceTripId,
                originTripUuid,
                replaceTripUuid,
            },
            suggestion,
            order,
            isItPartialRebooking: isCustom,
        });
    };

    if (isLoading) {
        return (
            <Section>
                <OrderBoxSkeleton />
            </Section>
        );
    }

    if (!groupByTrip) {
        return null;
    }

    return (
        <>
            <Pagination<[string, Map<number, Order>]>
                items={Array.from(groupByTrip)}
            >
                {(items) => {
                    return items.map(([group, orders]) => {
                        const allOrders = Array.from(orders);
                        const paxSize = allOrders?.map(([, order]) => {
                            return order.orderDetails.passengers.length;
                        });
                        return (
                            <Fragment key={group}>
                                {isFetching === false ? (
                                    <InfoStrip plain>
                                        {/** Passengers traveling */}
                                        {translate('group-by-trip.title')}{' '}
                                        <strong>
                                            {group}{' '}
                                            <Tag display="outlined" small>
                                                <small>
                                                    {paxSize.reduce(
                                                        (total, value) => {
                                                            const result =
                                                                total + value;
                                                            return result;
                                                        },
                                                        0
                                                    )}
                                                </small>
                                            </Tag>
                                        </strong>
                                    </InfoStrip>
                                ) : null}
                                {allOrders?.map(([, order]) => {
                                    return (
                                        <Section key={order.orderId}>
                                            <OrderBox
                                                rebookingStatus={
                                                    rebookingStatuses[
                                                        order.orderId
                                                    ]
                                                }
                                                key={order.orderId}
                                                orderId={order.orderId}
                                                trip={order.trip}
                                                originTripId={
                                                    order.originTripId
                                                }
                                                legacyOriginTripId={
                                                    order.legacyOriginTripId
                                                }
                                                orderDetails={
                                                    order.orderDetails
                                                }
                                                suggestions={
                                                    order.trip?.suggestions
                                                }
                                                alternative={
                                                    alternatives[order.orderId]
                                                }
                                                customAlternative={
                                                    customAlternatives[
                                                        order.orderId
                                                    ]
                                                }
                                                onSelect={selectOption(order)}
                                                selected={
                                                    selectedOptions[
                                                        order.orderId
                                                    ]?.replaceTripId
                                                }
                                                showAlternatives={
                                                    showAlternatives
                                                }
                                                selectedStop={
                                                    selectedStops?.[
                                                        order.orderId
                                                    ]
                                                }
                                                isLoading={isFetching}
                                            />
                                        </Section>
                                    );
                                })}
                            </Fragment>
                        );
                    });
                }}
            </Pagination>

            {alternativesDialog !== null && (
                <Alternatives
                    rideUuid={rideId}
                    onClose={() => {
                        showAlternatives(null);
                    }}
                    isShow={true}
                    order={alternativesDialog}
                    isGhost={isGhost}
                    altRideId={ride?.data?.rideId}
                />
            )}
        </>
    );
}
