import classNames from 'classnames';
import OptionCard from '../OptionCard';
import { Button, Box, Skeleton } from '@flixbus/honeycomb-react';
import { Icon, IconEdit, IconOffer } from '@flixbus/honeycomb-icons-react';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';
import { Suggestion, CustomSuggestion } from '../../dataLayer/classes/Trip';
import { SelectedOption } from '../OptionCard/OptionCard';

export interface OptionSlotProps {
    orderId: string | number;
    originTripId: string;
    legacyOriginTripId?: string;
    originalArrival: string;
    originalDeparture: string;
    options?: Suggestion[];
    alternative?: Suggestion;
    customAlternative?: CustomSuggestion;
    rebookById?: Suggestion;
    selected?: string;
    onSelect: (arg0: SelectedOption) => void;
    onAlternatives: (arg0: string[]) => void;
    noData?: boolean;
    noRebookingAllowed?: boolean;
    selectedStop?: string;
    originTicketId: string;
    isLoading: boolean;
}

export default function OptionsSlot({
    orderId,
    originalArrival,
    originalDeparture,
    options = [],
    originTripId,
    legacyOriginTripId,
    alternative,
    customAlternative,
    selected,
    onSelect,
    onAlternatives,
    noData,
    noRebookingAllowed,
    selectedStop,
    originTicketId,
    isLoading,
}: OptionSlotProps) {
    const translate: Translate = useTranslate();

    if (noData === true) {
        return (
            <div className="rbk-options-slot">
                <Box>{translate('order-box.no-trip-data-text')}</Box>
            </div>
        );
    }
    if (noRebookingAllowed === true) {
        return (
            <div className="rbk-options-slot rbk-options-slot--full-width">
                <Box>
                    {translate('order-box.no-rebooking-allowed-after-24-hours')}
                </Box>
            </div>
        );
    }

    const originalDepartureTimestamp = new Date(originalDeparture).getTime();

    let later: Suggestion | undefined,
        earlier: Suggestion | undefined,
        originalIndex: number = 0,
        fastSuggestionsIds: string[] = [];

    // get earlier and later options
    /**
     * @TODO
     * move this logic to suggestions collection
     */
    if (options.length === 1) {
        const { departureTime, uid } = options[0];
        const departureTimeStamp = new Date(departureTime).getTime();
        if (originalDepartureTimestamp <= departureTimeStamp) {
            later = uid === originTripId ? undefined : options[0];
            later && fastSuggestionsIds.push(later.legacyUid);
        } else {
            earlier = uid === originTripId ? undefined : options[0];
            earlier && fastSuggestionsIds.push(earlier.legacyUid);
        }
    } else if (options.length > 1) {
        originalIndex = options.findIndex(
            (option) =>
                option.uid === originTripId &&
                option.departureTime === originalDeparture &&
                option.arrivalTime === originalArrival
        );

        if (originalIndex !== -1) {
            earlier = options[originalIndex - 1];
            earlier?.legacyUid && fastSuggestionsIds.push(earlier.legacyUid);
            later = options[originalIndex + 1];
            later?.legacyUid && fastSuggestionsIds.push(later.legacyUid);
        } else {
            options.forEach(function (option) {
                earlier =
                    !earlier && option.departureTime <= originalDeparture
                        ? option
                        : undefined;
                later =
                    !later && option.departureTime >= originalDeparture
                        ? option
                        : undefined;
            });
            earlier?.legacyUid && fastSuggestionsIds.push(earlier.legacyUid);
            later?.legacyUid && fastSuggestionsIds.push(later.legacyUid);
        }
    }

    const alternativesCss = classNames('rbk-options-slot__alternatives', {
        'rbk-options-slot__alternatives--selected':
            selected && selected === alternative?.legacyUid,
    });
    /**
     * @todo back after implementing Rebook by Id
     */
    // const rideByIdCss = classNames('rbk-options-slot__alternatives', {
    //     'rbk-options-slot__alternatives--selected':
    //         selected && selected === rebookById?.legacyUid,
    // });
    const alternativeUid =
        alternative?.legacyUid || customAlternative?.legacyUid;

    return (
        <div className="rbk-options-slot">
            {!isLoading ? (
                <>
                    <div className="rbk-options-slot__options">
                        {earlier && (
                            <div className="ribbon">
                                <span>{translate('earlier')}</span>
                            </div>
                        )}
                        <OptionCard
                            orderId={orderId}
                            originTripId={originTripId}
                            legacyOriginTripId={legacyOriginTripId}
                            suggestion={earlier}
                            selected={selected === earlier?.legacyUid}
                            altLabel={translate('no_earlier_option')}
                            onSelect={onSelect}
                            originalArrival={originalArrival}
                            originalDeparture={originalDeparture}
                            originTicketId={originTicketId}
                        />
                    </div>
                    <div className="rbk-options-slot__options">
                        {later && (
                            <div className="ribbon">
                                <span>{translate('later')}</span>
                            </div>
                        )}
                        <OptionCard
                            orderId={orderId}
                            originTripId={originTripId}
                            legacyOriginTripId={legacyOriginTripId}
                            suggestion={later}
                            selected={selected === later?.legacyUid}
                            altLabel={translate('no_later_option')}
                            onSelect={onSelect}
                            originalArrival={originalArrival}
                            originalDeparture={originalDeparture}
                            originTicketId={originTicketId}
                        />
                    </div>
                    <div className={alternativesCss}>
                        <OptionCard
                            orderId={orderId}
                            originTripId={originTripId}
                            legacyOriginTripId={legacyOriginTripId}
                            suggestion={alternative}
                            customSuggestion={customAlternative}
                            selected={selected === alternativeUid}
                            altLabel={
                                <Button
                                    appearance="link"
                                    onClick={() =>
                                        onAlternatives(fastSuggestionsIds)
                                    }
                                >
                                    <Icon InlineIcon={IconOffer} />{' '}
                                    {translate('alternatives')}
                                </Button>
                            }
                            altLabelShift={
                                <Button
                                    appearance="link"
                                    onClick={() =>
                                        onAlternatives(fastSuggestionsIds)
                                    }
                                >
                                    <Icon InlineIcon={IconEdit} />
                                </Button>
                            }
                            onSelect={onSelect}
                            originalArrival={originalArrival}
                            originalDeparture={originalDeparture}
                            originTicketId={originTicketId}
                            selectedStop={selectedStop}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="rbk-options-slot__options">
                        <Skeleton height="lg" />
                    </div>
                    <div className="rbk-options-slot__options">
                        <Skeleton height="lg" />
                    </div>
                    <div className={alternativesCss}>
                        <Skeleton height="lg" />
                    </div>
                </>
            )}
            {/**
             * @todo back after implementing Rebook by Id
             */}
            {/* <div className={rideByIdCss}>
                    <OptionCard
                        orderId={orderId}
                        originTripId={originTripId}
                        legacyOriginTripId={legacyOriginTripId}
                        suggestion={rebookById}
                        selected={selected === rebookById?.legacyUid}
                        altLabel={
                            <Button link>
                                <Icon InlineIcon={IconOffer} /> Ride by Id{' '}
                            </Button>
                        }
                        altLabelShift={
                            <Button link onClick={onAlternatives}>
                                <Icon InlineIcon={IconEdit} />
                            </Button>
                        }
                        onSelect={onSelect}
                        originalArrival={originalArrival}
                        originalDeparture={originalDeparture}
                    />
                </div> */}
        </div>
    );
}
