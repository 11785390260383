import { Box, FormRow, Grid, GridCol, Infobox } from '@flixbus/honeycomb-react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import useAppState from '../../dataLayer/AppState/useAppState';
import {
    ALTERNATIVES_ADD,
    ALTERNATIVES_REMOVE,
    CUSTOM_ALTERNATIVES_ADD,
    CUSTOM_ALTERNATIVES_REMOVE,
    OPTION_TOGGLE,
    SELECTED_STOPS_SELECT,
    SELECTED_STOPS_UNSELECT,
    SINGLE_OPTION_TOGGLE,
} from '../../dataLayer/AppState/actions';
import useAlternatives from '../../dataLayer/useAlternatives';
import DatePicker from '../DatePicker/DatePicker';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import OriginalOrderInfo from '../OriginalOrderInfo/OriginalOrderInfo';
import AlternativesList from './AlternativesList';
import AlternativesSkeleton from './AlternativesSkeleton';
import { useTranslate, Translate } from '../../system/i18n/useTranslate';
import StartStationSelector from '../StartStationSelector';
import genCustomSuggestion from './genCustomSuggestion';
import usePartialAlternatives from '../../dataLayer/usePartialAlternatives';
import {
    SHORT_DATE_FORMAT,
    calculateDatePickerStartDate,
    formatTimezoneColon,
    FULL_DATE_TIMEZONE_FORMAT,
} from '../../utils/index';
import { OrderAlternatives } from '../OrderBox/OrderBox';
import { Suggestion } from '../../dataLayer/classes/Trip';
import { TRACKER_ADD } from '../../dataLayer/AppState/actions';
import useSingleAltTripTracking from '../../DataDog/useSingleAltTripTracking';

export interface AlternativesProps {
    rideUuid: string;
    order: OrderAlternatives;
    isShow: boolean;
    onClose: () => void;
    isGhost?: boolean;
    altRideId?: string | number;
}

export default function Alternatives({
    rideUuid,
    order,
    isShow = true,
    onClose,
    isGhost,
    altRideId,
}: AlternativesProps) {
    const originFullTrip = order.trip;
    if (!originFullTrip) throw new Error('No FULL suggestion found');

    const [limitOptionsTime, setLimitOptionsTime] = useState<
        string | undefined
    >(undefined);
    const [date, setDate] = useState(originFullTrip.departureTime);
    const [appState, dispatch] = useAppState();
    const {
        selectedStops = {},
        customAlternatives = {},
        alternatives: storeAlternatives = {},
    } = appState;
    const selectedStop = selectedStops[order.orderId];
    const customAlternative = customAlternatives[order.orderId];
    const translate: Translate = useTranslate();
    const formattedSelectedDate = dayjs(date).format(SHORT_DATE_FORMAT);

    const tracker = useSingleAltTripTracking(
        'single-rebooking',
        Number(order.orderId)
    );

    const {
        data: alternatives,
        isFetching: isLoading,
        isError,
        error,
    } = useAlternatives(
        rideUuid,
        [order.orderId],
        formatTimezoneColon(date),
        isGhost,
        altRideId,
        { enabled: !Boolean(selectedStop) }
    );

    const {
        data: partialAlternatives,
        isFetching: partialIsLoading,
        isError: partialIsError,
        error: partialError,
    } = usePartialAlternatives(
        rideUuid,
        order.orderId,
        formatTimezoneColon(date),
        [selectedStop, originFullTrip.destinationStationId],
        { enabled: Boolean(selectedStop) }
    );

    const originFullTripIds = {
        originRideId: originFullTrip.rideId,
        originRideUid: originFullTrip.rideUid,
        originRideUuid: originFullTrip.rideUuid,
        originDestinationStationId: originFullTrip.destinationStationId,
        originStartStationId: originFullTrip.startStationId,
    };

    useEffect(() => {
        if (selectedStop) {
            const rideWithSelectedStopDestination =
                originFullTrip?.icInfo?.find((ic) => {
                    return ic.destinationStationId === selectedStop;
                });
            if (rideWithSelectedStopDestination === undefined) {
                return;
            }
            setDate(rideWithSelectedStopDestination.arrivalTime);
            setLimitOptionsTime(rideWithSelectedStopDestination.arrivalTime);
            return;
        }

        setDate(originFullTrip.departureTime);
        setLimitOptionsTime(undefined);
    }, [selectedStop, originFullTrip]);

    function dateChange(timestamp: number) {
        setDate(dayjs(timestamp).format(FULL_DATE_TIMEZONE_FORMAT));
    }
    function setSelectedStop(id: string) {
        const { orderId } = order;
        if (id === '') {
            dispatch(SELECTED_STOPS_UNSELECT, orderId);
            return;
        }
        dispatch(SELECTED_STOPS_SELECT, { [orderId]: id });

        if (tracker) {
            dispatch(TRACKER_ADD, tracker);
            tracker.track('select-IC-station', {
                icStation: id,
                selectedDate: formattedSelectedDate,
            });
        }
    }

    const onSelect = useCallback(
        (suggestion: Suggestion) => {
            const { orderId, legacyOriginTripId, originTripId } = order;
            const selectedStop = selectedStops[orderId];

            if (selectedStop) {
                const customSuggestion = genCustomSuggestion(
                    suggestion,
                    originFullTrip,
                    { originTripId, legacyOriginTripId },
                    selectedStop
                );

                if (storeAlternatives[orderId]) {
                    dispatch(ALTERNATIVES_REMOVE, { orderId: orderId });
                }

                dispatch(CUSTOM_ALTERNATIVES_ADD, {
                    orderId: orderId,
                    alternative: customSuggestion,
                });
                dispatch(OPTION_TOGGLE, {
                    orderId: orderId,
                    option: {
                        originTripId: legacyOriginTripId,
                        replaceTripId: customSuggestion?.legacyUid,
                        originTripUuid: originTripId,
                        replaceTripUuid: customSuggestion?.uid,
                        isItPartialRebooking: true,
                    },
                });
                dispatch(SINGLE_OPTION_TOGGLE, {
                    orderId: orderId,
                    option: {
                        originTripId: legacyOriginTripId,
                        replaceTripId: customSuggestion?.legacyUid,
                        originTripUuid: originTripId,
                        replaceTripUuid: customSuggestion?.uid,
                    },
                    suggestion: customSuggestion,
                    order: order,
                    isItPartialRebooking: true,
                });
            } else {
                if (customAlternative) {
                    dispatch(CUSTOM_ALTERNATIVES_REMOVE, { orderId: orderId });
                }

                dispatch(ALTERNATIVES_ADD, {
                    orderId: orderId,
                    alternative: suggestion,
                });
                dispatch(OPTION_TOGGLE, {
                    orderId: orderId,
                    option: {
                        originTripId: legacyOriginTripId,
                        replaceTripId: suggestion?.legacyUid,
                        originTripUuid: originTripId,
                        replaceTripUuid: suggestion?.uid,
                        isItPartialRebooking: false,
                    },
                });
                dispatch(SINGLE_OPTION_TOGGLE, {
                    orderId: orderId,
                    option: {
                        originTripId: legacyOriginTripId,
                        replaceTripId: suggestion?.legacyUid,
                        originTripUuid: originTripId,
                        replaceTripUuid: suggestion?.uid,
                    },
                    suggestion: suggestion,
                    order: order,
                    isItPartialRebooking: false,
                });
            }

            if (tracker) {
                dispatch(TRACKER_ADD, tracker);
                tracker.track('select', {
                    altTrip: `${suggestion.startStationName}-${suggestion.destinationStationName}`,
                    selectedDate: dayjs(date).format(SHORT_DATE_FORMAT),
                });
            }

            onClose();
        },
        [
            dispatch,
            order,
            onClose,
            originFullTrip,
            selectedStops,
            customAlternative,
            storeAlternatives,
            tracker,
            date,
        ]
    );

    function body() {
        if (isLoading || partialIsLoading) {
            return <AlternativesSkeleton />;
        }
        if (isError || partialIsError) {
            return (
                <Infobox appearance="danger">
                    {String(error || partialError)}
                </Infobox>
            );
        }

        const originTicketId: string =
            order.orderDetails.passengers.length > 0
                ? order.orderDetails.passengers[0].ticketId || ''
                : '';

        if (partialAlternatives && selectedStop) {
            const breakingPointTrip = originFullTrip?.icInfo?.find(
                (r) => r.startStationId === selectedStop
            );
            if (breakingPointTrip === undefined) {
                return (
                    <Box>
                        Something goes wrong select try to select another stop
                    </Box>
                );
            }
            const { startStationName, destinationStationName, departureTime } =
                breakingPointTrip;
            const { arrivalTime = '' } = originFullTrip || {};

            return (
                <AlternativesList
                    options={partialAlternatives}
                    dateSelected={formattedSelectedDate}
                    originTrip={order.originTripId}
                    originFullTripIds={originFullTripIds}
                    originalStartStationName={startStationName}
                    originalDestinationStationName={destinationStationName}
                    originalArrivalTime={arrivalTime}
                    originalDepartureTime={departureTime}
                    onSelect={onSelect}
                    originTicketId={originTicketId}
                    selectedStop={selectedStop}
                    limitOptionsTime={limitOptionsTime}
                />
            );
        }

        const alternativeContent = alternatives;
        const alternativeFull = alternativeContent?.[0].trip;

        if (alternativeFull && !selectedStop) {
            const {
                suggestions,
                startStationName,
                destinationStationName,
                arrivalTime,
                departureTime,
            } = alternativeFull;
            const { fastSuggestionsIds } = order;
            return (
                <AlternativesList
                    options={suggestions}
                    dateSelected={formattedSelectedDate}
                    originTrip={order.originTripId}
                    originFullTripIds={originFullTripIds}
                    originalStartStationName={startStationName}
                    originalDestinationStationName={destinationStationName}
                    originalArrivalTime={arrivalTime}
                    originalDepartureTime={departureTime}
                    onSelect={onSelect}
                    fastSuggestionsIds={fastSuggestionsIds}
                    originTicketId={originTicketId}
                />
            );
        }

        return <Box>{translate('no-alternatives')}</Box>;
    }

    return (
        <ModalWrapper
            title={`${translate('alternatives_for')} ${order.orderId}`}
            isShow={isShow}
            onModalClose={onClose}
        >
            <OriginalOrderInfo
                //@ts-ignore
                trip={originFullTrip}
                orderDetails={order.orderDetails}
                selectedStop={selectedStop}
            />
            {originFullTrip.icInfo && originFullTrip.icInfo.length > 0 ? (
                <Grid justify="center">
                    <GridCol size={6}>
                        <FormRow spacing="6">
                            <StartStationSelector
                                icInfo={originFullTrip.icInfo}
                                onSelect={setSelectedStop}
                                value={selectedStop}
                            />
                        </FormRow>
                    </GridCol>
                </Grid>
            ) : null}
            <DatePicker
                startDate={calculateDatePickerStartDate(date)}
                onSelect={dateChange}
                utcOffset={originFullTrip.departureUtcOffset}
                setDate={calculateDatePickerStartDate(date)}
            />
            {body()}
        </ModalWrapper>
    );
}
